import CmsEventCalendar from './components/CmsEventCalendar/CmsEventCalendar';
import CmsContactBox from './components/CmsContactBox/CmsContactBox';
import CmsTwitter from './components/CmsTwitter/CmsTwitter';
import CmsNewsletter from './components/CmsNewsletter/CmsNewsletter';
import CmsFullHero from './components/CmsFullHero/CmsFullHero';
import styles from './CmsComponents.module.scss';
import {
  CmsAccordion,
  CmsAlert,
  CmsBenefits,
  CmsBlogCategory,
  CmsBlogPosts,
  CmsBottina,
  CmsBusinessJourneyWizard,
  CmsButton,
  CmsCalendar,
  CmsCallToAction,
  CmsCard,
  CmsCardList,
  CmsChargerMap,
  CmsColumn,
  CmsColumns,
  CmsContactCards,
  CmsContactForm,
  CmsContactFormStrompreis,
  CmsCookieConsent,
  CmsCountdown,
  CmsCustomerWizard,
  CmsCustomerWizardFilter,
  CmsDavinci,
  CmsDownloadAccordion,
  CmsDynamicIframe,
  CmsEnergieSparCheck,
  CmsFactBox,
  CmsFaqCategoriesList,
  CmsFaqFeedback,
  CmsFaqFilter,
  CmsFaqMostViewed,
  CmsFaqOverview,
  CmsFileList,
  CmsGallery,
  CmsGalleryExtended,
  CmsGalleryExtendedVideo,
  CmsGroup,
  CmsHeading,
  CmsHeatInstallationForm,
  CmsHeatPumpRecommender,
  CmsHeatRecommender,
  CmsHelpSearch,
  CmsIcon,
  CmsIconHeadline,
  CmsImage,
  CmsInfobox,
  CmsInstagramLinklist,
  CmsInternetRecommender,
  CmsList,
  CmsMainTeaser,
  CmsOrderFormTanke,
  CmsPageStrip,
  CmsPostalCodeQueryWidget,
  CmsProductBenefits,
  CmsProductRecommender,
  CmsProducts,
  CmsQuizWidget,
  CmsSeparator,
  CmsSpacer,
  CmsSteps,
  CmsSubCategories,
  CmsTable,
  CmsTestimonials,
  CmsTextImage,
  CmsTimeline,
  CmsTopFaqs,
  CmsVimeo,
  CmsVotingWidget,
  CmsYoutube,
  Container,
  CoreParagraph,
  CoreQuote,
  ErrorBoundary,
} from '@wienenergiegithub/components';

import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import BlogCard from '../BlogCard/BlogCard';

const components = {
  'acf/testimonials': CmsTestimonials,
  'acf/accordion': CmsAccordion,
  'acf/graphicsposts': props => <CmsBlogPosts blogCard={BlogCard} {...props} />,
  'acf/studiesposts': props => <CmsBlogPosts blogCard={BlogCard} {...props} />,
  'acf/downloadaccordion': CmsDownloadAccordion,
  'acf/infobox': CmsInfobox,
  'acf/instagram': CmsInstagramLinklist,
  'acf/alert': CmsAlert,
  'acf/benefits': CmsBenefits,
  'acf/contactbox': CmsContactBox,
  'acf/blogposts': props => <CmsBlogPosts blogCard={BlogCard} {...props} />,
  'acf/blogcategory': CmsBlogCategory,
  'acf/bottina': CmsBottina,
  'acf/button': CmsButton,
  'acf/charger-map': CmsChargerMap,
  'acf/calendar-overview': CmsCalendar,
  'acf/customer-wizard': CmsCustomerWizard,
  'acf/customer-wizard-filter': CmsCustomerWizardFilter,
  'acf/card': CmsCard,
  'acf/call-to-action': CmsCallToAction,
  'acf/contact-form': CmsContactForm,
  'acf/cardlist': CmsCardList,
  'acf/cookie-consent': CmsCookieConsent,
  'core/columns': CmsColumns,
  'core/column': CmsColumn,
  'acf/event': CmsEventCalendar,
  'acf/dynamic-iframe': CmsDynamicIframe,
  'acf/downloads': CmsFileList,
  'acf/energie-spar-check': CmsEnergieSparCheck,
  'acf/faq-feedback-form': CmsFaqFeedback,
  'acf/faq-search': CmsHelpSearch,
  'acf/faq-categories': CmsFaqOverview,
  'acf/faq-categories-list': CmsFaqCategoriesList,
  'acf/faq-filter': CmsFaqFilter,
  'acf/faq-top-faqs': CmsTopFaqs,
  'acf/twitter': CmsTwitter,
  'acf/faq-most-viewed': CmsFaqMostViewed,
  'core/gallery': CmsGallery,
  'acf/gallery-extended': CmsGalleryExtended,
  'acf/gallery-extended-video': CmsGalleryExtendedVideo,
  'core/group': CmsGroup,
  'core/heading': CmsHeading,
  'acf/hero': CmsTextImage,
  'acf/full-hero': CmsFullHero,
  'acf/heatrecommender': CmsHeatRecommender,
  'acf/heatpumprecommender': CmsHeatPumpRecommender,
  'acf/heat-installation-form': CmsHeatInstallationForm,
  'acf/icon': CmsIcon,
  'core/image': CmsImage,
  'acf/internetrecommender': CmsInternetRecommender,
  'acf/quiz-widget': CmsQuizWidget,
  'acf/voting-widget': CmsVotingWidget,
  'acf/votinglist-widget': CmsVotingWidget,
  'core/list': CmsList,
  'acf/newsletter': CmsNewsletter,
  'acf/orderformtanke': CmsOrderFormTanke,
  'acf/productrecommender': CmsProductRecommender,
  'core/paragraph': CoreParagraph,
  'core/quote': CoreQuote,
  'acf/step': CmsSteps,
  'acf/products': CmsProducts,
  'acf/product-benefits': CmsProductBenefits,
  'core/separator': CmsSeparator,
  'core/spacer': CmsSpacer,
  'core/shortcode': CoreParagraph,
  'acf/subcategories': CmsSubCategories,
  'core/table': CmsTable,
  'acf/teasernew': CmsMainTeaser,
  'acf/vimeo': CmsVimeo,
  'acf/pagestrip': CmsPageStrip,
  'acf/davinci': CmsDavinci,
  'acf/countdown': CmsCountdown,
  'acf/contactcards': CmsContactCards,
  'acf/timeline': CmsTimeline,
  'acf/iconheadline': CmsIconHeadline,
  // @TODO: core/embed should be removed after WP 5.8 version
  'core/embed': CmsYoutube,
  'core-embed/youtube': CmsYoutube,
  'acf/businessjourneywizard': CmsBusinessJourneyWizard,
  'acf/contactformstrompreis': CmsContactFormStrompreis,
  'acf/factbox': CmsFactBox,
  'acf/pvavailabilitywidget': CmsPostalCodeQueryWidget,
};

export const CmsComponents = ({
  data: {
    name,
    acf,
    attributes,
    saveContent,
    renderedContent,
    orderClassName,
    meta,
    size,
    containerDimension,
    noMargin,
    groupTheme,
    trackingName,
    ...restData
  },
  scrollIndex,
  blogTemplate,
}) => {
  const Tag = components[name];
  if (!Tag) {
    return (
      <p>
        Component <strong>{name}</strong> is not implemented
      </p>
    );
  }

  const componentName = meta.trackId;
  // const componentName = meta.trackId || getBlockTitle(name, restData, '');
  const containerSize = meta?.container || blogTemplate;

  const content = (
    <div
      className={classNames(
        { [styles[meta?.align]]: name === 'core/column' },
        orderClassName,
      )}
    >
      <Container size={containerSize || Tag.size || size}>
        <div
          className={classNames(styles.container, {
            [styles[meta?.align]]: meta?.align && name !== 'core/column',
            [styles.noMargin]: Tag.noMargin || noMargin,
          })}
          data-componentname={componentName}
          data-purpose={name}
        >
          <Tag
            /* This guarantees three things:
              - Avoid unwanted interferences by creating an entirely new prop containerDimension
              - Take containerSize value only if one has been set
              - Pass down prop to the very bottom of the element tree
              */
            CmsComponents={CmsComponents}
            ScrollIndexVertical={scrollIndex}
            containerDimension={containerDimension || containerSize || null}
            groupTheme={groupTheme}
            meta={meta}
            renderedContent={renderedContent}
            saveContent={saveContent}
            trackingName={trackingName}
            {...restData}
            {...(acf || attributes)}
          />
        </div>
      </Container>
    </div>
  );

  return <ErrorBoundary>{content}</ErrorBoundary>;
};

CmsComponents.propTypes = {
  blogTemplate: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
  scrollIndex: PropTypes.number,
};

CmsComponents.defaultProps = {
  blogTemplate: false,
  data: {},
  scrollIndex: null,
};

export default CmsComponents;
